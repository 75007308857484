import React from 'react';

class LoginRedirect extends React.Component {
  componentDidMount() {
    window.location = "https://app.bulletn.co/login";
  }

  render() {
    return <div>Redirecting to app.bulletn.co/login...</div>
  }
}

export default LoginRedirect;
